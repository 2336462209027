import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';


const AboutUsSection = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  const thresholdValue = isMobile ? 0.1 : 0.5; // Adjust the mobile threshold as needed

  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: thresholdValue,
  });

  const controls = useAnimation();

  useEffect(() => {
    if (isMobile) {
      controls.start('visible'); // Set to end state immediately for mobile
    } else {
      if (inView) {
        controls.start('visible');
      } else {
        controls.start('hidden');
      }
    }
  }, [controls, inView, isMobile]);
  

  const variants = {
    hidden: {
      opacity: 0,
      x: -100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.7,
      },
    },
  };

  return (
    <section id="about" className="flex items-center py-10 bg-white py-20 font-poppins dark:bg-gray-800">
      <div className="justify-center flex-1 max-w-6xl py-4 mx-auto lg:py-6 md:px-6">
        <div className="flex flex-wrap">
          <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0">
            <div className="relative" ref={ref}>
              <motion.img
                src="./assets/about.webp"
                alt=""
                className="relative z-40 object-cover w-full h-96 lg:rounded-tr-[80px] lg:rounded-bl-[80px] rounded"
                initial={isMobile ? {} : { opacity: 0, x: 50 }}
                animate={controls}
                variants={variants}
              />
              <motion.div 
              initial={isMobile ? {} : { opacity: 0, x: 50 }}
              animate={controls}
              variants={variants}
              className="absolute z-10 hidden w-full h-full bg-red-400 rounded-bl-[80px] rounded -bottom-6 right-6 lg:block"
              ></motion.div>
              <div className="absolute z-50 text-red-400 transform -translate-y-1/2 cursor-pointer top-1/2 left-[46%] hover:text-red-500">
              </div>
            </div>
          </div>
          <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0">
            <div className="relative">
              
              <motion.h1
                className="pl-2 text-3xl font-bold border-l-8 border-red-400 md:text-5xl dark:text-white"
                initial={isMobile ? {} : { opacity: 0, x: 50 }}
                animate={controls}
                variants={variants}
              >
                Welcome to Fame
              </motion.h1>
            </div>
            <motion.p
              className="mt-6 mb-4 font-regular text-base leading-7 text-gray-500 dark:text-gray-400"
              initial={isMobile ? {} : { opacity: 0, x: 50 }}
              animate={controls}
              variants={variants}
            >
              We are Fame.net, a marketing and web development agency dedicated to helping businesses thrive in the digital world. Our team of experts combines creativity, technical expertise, and strategic thinking to deliver outstanding results for our clients.
            </motion.p>
            <motion.p
              className="mt-2 mb-10 font-regular text-base leading-7 text-gray-500 dark:text-gray-400"
              initial={isMobile ? {} : { opacity: 0, x: 50 }}
              animate={controls}
              variants={variants}
            >
            At Fame.net, we believe in the transformative power of technology and innovative marketing. Our approach is client-centric, tailoring unique digital solutions that enhance visibility, boost customer engagement, and drive business growth. Leveraging cutting-edge tools and trends, we deliver not just websites or ad campaigns, but comprehensive digital experiences that resonate with your target audience and affirm your brand's presence in the market. With Fame.net, you're not just getting a service, you're gaining a strategic partner committed to your business success.
            </motion.p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
