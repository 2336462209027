// PrivacyPolicyContent.js
import React from 'react';

const PrivacyPolicyContent = () => (
    <div>
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            This Privacy Policy outlines how Fame.net, owned by Swaha Capital LLC ("we", "our", or "us"), collects, uses, and discloses your personal information. By using our website, you agree to the practices described in this policy.
        </p>
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            We respect your privacy and are committed to protecting it. This policy explains our practices concerning the collection, use, and sharing of your personal information. By visiting our website, you accept the practices described in this policy.
        </p>
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            <strong>Information Collection:</strong> We collect basic analytic data to understand how our website is used and to improve user experience. This may include pages visited, time spent on pages, and the type of browser used. Additionally, any information you voluntarily provide through our contact form, such as your name, email, and message, will be collected and stored.
        </p>
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            <strong>Use of Information:</strong> The data we collect is used to better understand our audience and enhance the user experience on our website. Information provided through the contact form is used to respond to inquiries or other such purposes for which you provided it.
        </p>
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            <strong>Information Sharing:</strong> We do not sell, trade, or rent your personal information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers.
        </p>
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            <strong>Protection of Information:</strong> We implement a variety of security measures to protect your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.
        </p>
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            <strong>Changes to this Policy:</strong> We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
        </p>
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            <strong>Contact Us:</strong> If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us through the contact form provided.
        </p>

    </div>
);

export default PrivacyPolicyContent;
