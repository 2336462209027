import React from 'react';

const TOSContent = () => (
    <div>
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-6">
            Welcome to Fame.net, owned and operated by Swaha Capital LLC. By accessing our website and utilizing our services, you agree to comply with and be bound by the following terms and conditions.
        </p>
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-6">
            Fame.net provides marketing and web development services. While we strive to deliver top-notch services, Fame.net makes no guarantees regarding the outcomes of our services.
        </p>
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400 mn-6">
            All content on this website, including but not limited to text, graphics, logos, and images, is the property of Swaha Capital LLC or its content providers, and is protected by copyright laws.
        </p>
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-6">
            Users are not allowed to reproduce, distribute, modify, or republish content from this website without the express permission of Swaha Capital LLC.
        </p>
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-6">
            While we make every effort to ensure the accuracy of the information on our website, Fame.net is not responsible for any errors or omissions or for the results obtained from the use of this information.
        </p>
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-6">
            Our website may contain links to external sites. While we strive to provide only quality links, we have no control over the content and nature of these sites. A link to an external site does not imply a recommendation or endorsement of the views expressed within them.
        </p>
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-6">
            Fame.net reserves the right to modify these terms and conditions at any time. Users are encouraged to check this page regularly to stay informed about changes.
        </p>
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-6">
            By using our website, you hereby consent to our terms and conditions and agree to its terms.
        </p>
    </div>
);

export default TOSContent;
