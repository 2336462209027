import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const PortfolioSection = () => {

  const projects = [
    {
      title: 'Aurox',
      description: 'As a media agency, we seamlessly coordinated all media purchases and drove the creative development for their signature product, the Aurox Wallet.',
      image: './assets/aurox.jpg',
    },
    {
      title: 'Swan Bitcoin',
      description: 'Serving as both a media and development agency, we skillfully orchestrated numerous sales campaigns and managed all facets of media buying.',
      image: './assets/swan.jpg',
    },
    {
      title: 'WYNN',
      description: 'As a media buying agency, our primary focus was fueling growth for their WYNNBET mobile app. Our contributions included the development of innovative MRAID creatives and the execution of several strategic programmatic media buying campaigns.',
      image: './assets/wynn.jpg',
    },
    {
      title: 'AstoriaVR',
      description: 'Entrusted with a $2 million budget for media buying and creative development, we effectively drove marketing strategies that resulted in a remarkable $15 million in sales revenue from our meticulously executed advertising campaigns.',
      image: './assets/astoria.jpg',
    },
  ];

  return (
    <section id="work" className="flex items-center justify-center bg-white py-20">
      <div className="justify-center flex-1 px-4 py-6 mx-auto max-w-7xl lg:py-4 md:px-6">
        <div className="mb-10 text-center">
          <h1 className="mb-4 text-3xl font-bold text-center">Portfolio</h1>
          <p className="mb-16 text-base text-center text-gray-500">
            Showcasing Successful Collaborations: Key Partnerships in Marketing and Web Development
          </p>
        </div>
        <div className="grid grid-cols-1 gap-4 lg:gap-8 sm:gap-4 sm:grid-cols-2 lg:grid-cols-2">
          {projects.map((project, index) => (
            <ProjectCard
              key={index}
              title={project.title}
              description={project.description}
              image={project.image}
              index={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
};


const ProjectCard = ({ title, description, image, index }) => {
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
      setIsMobile(window.innerWidth <= 768);
  }, []);

  const slideDirection = index % 2 === 0 ? -100 : 100;

  const slideInVariants = {
    hidden: isMobile ? { opacity: 1, x: 0 } : { opacity: 0, x: slideDirection },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <motion.div
      ref={ref}
      className="flex flex-col flex-wrap mb-0 overflow-hidden rounded lg:flex-row"
      initial={isMobile ? 'visible' : 'hidden'}
      animate={isMobile ? 'visible' : (inView ? 'visible' : 'hidden')}
      variants={slideInVariants}
      transition={{ duration: 0.5 }}
    >
    <div className="w-full overflow-hidden lg:w-2/4 h-80">
      <img className="object-cover w-full h-full transition-all hover:scale-110" src={image} alt={title} />
    </div>
    <div className="relative flex self-center flex-1 p-8 ml-0 bg-gray-50 border rounded shadow lg:items-center lg:-ml-12">
      <div>
        <h2 className="mb-2 text-xl font-bold">{title}</h2>
        <p className="mb-4 text-sm text-gray-400">{description}</p>
      </div>
    </div>
  </motion.div>
);
};

export default PortfolioSection;

