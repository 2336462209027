import React, { useState } from 'react';
import { Link } from 'react-scroll';

const Navbar = () => {
    const [open, setOpen] = useState(false);
    const menuItems = [
        { id: 'top', name: 'Home' },
        { id: 'about', name: 'About' },
        { id: 'services', name: 'Services' },
        { id: 'work', name: 'Our Work' },
        { id: 'contact', name: 'Contact' },
    ];

    return (
        <section className="shadow-lg bg-gray-800 font-poppins">
            <div className="max-w-6xl px-4 mx-auto">
                <nav className="flex items-center justify-between py-4">
                    <ul className="hidden lg:w-auto lg:space-x-12 lg:items-center">
                        {menuItems.map(item => (
                            <li key={item.id}>
                                <Link
                                    to={item.id}
                                    smooth={true}
                                    className="text-sm text-white hover:text-red-700"
                                >
                                    {item.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <div className="lg:flex">
                        <a href="/" className="text-3xl text-white font-semibold leading-none">
                            FAME.NET
                        </a>
                    </div>
                    <button
                        className={`flex items-center px-3 py-2 text-red-700 border-2 border-red-500 rounded navbar-burger hover:text-red-800 hover:border-red-300 ${
                            open ? 'text-red-800 border-red-300' : ''
                        }`}
                        onClick={() => setOpen(!open)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-list"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fillRule="evenodd"
                                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                            />
                        </svg>
                    </button>
                </nav>
                <div
                    className={`fixed inset-0 w-full opacity-50 ${
                        open ? 'translate-x-0 ease-in-opacity-100' : '-translate-x-full ease-out opacity-0'
                    }`}
                ></div>
                <div
                    className={`absolute inset-0 z-40 h-screen p-3 text-gray-400 duration-500 transform bg-red-50 w-80 lg:w-96 lg:block ${
                        open ? 'translate-x-0 ease-in-opacity-100' : '-translate-x-full ease-out opacity-0'
                    }`}
                >
                    <div className="px-4">
                        <div className="flex justify-between">
                            <a className="py-2 text-3xl text-gray-700 font-extrabold" href="/">
                                <img alt="logo" src="./assets/logo.png"></img>
                            </a>
                            <button className="p-2 text-gray-700 rounded-md hover:text-red-500 lg:block" onClick={() => setOpen(false)}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    className="bi bi-x-circle"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                    />
                                    <path
                                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                                    />
                                </svg>
                            </button>
                        </div>
                        <ul className="text-left mt-7">
                            {menuItems.map(item => (
                                <li key={item.id} className="pb-4">
                                    <a
                                        href={`#${item.id}`}
                                        className="text-xl text-gray-600 font-bold hover:text-red-400"
                                    >
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Navbar;
