import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import AboutUsSection from './components/AboutUsSection';
import FeaturesSection from './components/Services';
import PortfolioSection from './components/PortfolioSection';
import ContactFormSection from './components/ContactFormSecton';
import Footer from './components/Footer';
import CookieConsent from './components/CookieConsent';

function App() {
  return (
    <>
      {/* Components Called Here */}
      <Header />
      <Hero />
      <AboutUsSection />
      <FeaturesSection />
      <PortfolioSection />
      <ContactFormSection />
      <Footer />
      <CookieConsent />
    </>
  );
}

export default App;
