import React, { useState, useEffect } from 'react';

const Hero = () => {
  const headerText = 'Accelerate Your Business Growth'; // Replace with your text
  const subheaderText = 'With Fame.net\'s Innovative Advertising & Web Development Services'; // Replace with your text
  const [visibleHeaderChars, setVisibleHeaderChars] = useState(0);
  const [visibleSubheaderChars, setVisibleSubheaderChars] = useState(0);

  useEffect(() => {
    const headerTimer = setInterval(() => {
      setVisibleHeaderChars((v) => v + 1);
    }, 100); // adjust speed as needed

    const subheaderTimer = setInterval(() => {
      setVisibleSubheaderChars((v) => v + 1);
    }, 60); // adjust speed as needed

    return () => {
      clearInterval(headerTimer);
      clearInterval(subheaderTimer);
    };
  }, []);

  return (
    <section id="hero" className="hero relative h-screen">
      <video className="heroVideo absolute w-auto min-w-full min-h-full z-0" autoPlay loop muted playsInline={true} src="./assets/hero.mp4" type="video/mp4" />
      <div className="heroOverlay absolute w-full h-full bg-black opacity-50 z-10"></div>
      <div className="heroText z-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
        <h1 className="heroHeadline text-5xl font-bold text-white">
          {headerText.slice(0, visibleHeaderChars)}
          <span className="animate-blink">|</span>
        </h1>
        <p className="heroSub text-xl font-regular text-white mt-4">
          {subheaderText.slice(0, visibleSubheaderChars)}
          <span className="animate-blink">|</span>
        </p>
        <div className="flex justify-center space-x-4 mt-8">
          <a href="#about" className="btnBlue px-6 py-2 rounded font-semibold text-white bg-red-600 hover:bg-red-800">Learn More</a>
          <a href="#contact" className="btnGray px-6 py-2 rounded font-semibold text-black bg-gray-300 hover:bg-gray-400">Contact Us</a>
        </div>
      </div>
    </section>
  );
};

export default Hero;
