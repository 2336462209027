import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faCode, faMobile } from '@fortawesome/free-solid-svg-icons';

const FeaturesSection = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  const [ref, inView] = useInView({threshold: 0.1});

  const serviceVariants = {
    hidden: { opacity: 0, y: 100, rotate: -180 },
    visible: { opacity: 1, y: 0, rotate: 0 },
  };

  const serviceCards = [
    {
      title: 'Online Marketing Services',
      description: 'We provide comprehensive online marketing services tailored to your business goals. From search engine optimization (SEO) and pay-per-click (PPC) advertising to social media marketing and content creation, we help you drive traffic, generate leads, and increase conversions.',
      icon: faChartLine,
    },
    {
      title: 'Web Development',
      description: 'Our web development services encompass creating customized, user-friendly websites that reflect your brand\'s identity and meet your business objectives. We leverage the latest web technologies and frameworks to build scalable and responsive websites.',
      icon: faCode,
    },
    {
      title: 'App Development',
      description: 'Our app development services cover the creation of innovative mobile applications for iOS and Android platforms. We prioritize usability, performance, and aesthetics to deliver seamless app experiences that engage users and drive business growth.',
      icon: faMobile,
    },
  ];

  return (
    <section id="services" className="flex items-center justify-center bg-gray-100 py-20">
      <div className="px-4 py-20 mx-auto max-w-7xl">
        <div className="max-w-xl mx-auto">
          <h1 className="mb-4 text-3xl font-bold text-center">Our Services</h1>
          <p className="mb-16 text-base font-regular text-center text-gray-500">
            We offer a range of services to help your business succeed in the digital world. From online marketing to web development and app development, we've got you covered.
          </p>
        </div>
        <div className="grid grid-cols-1 gap-x-4 gap-y-6 lg:gap-x-8 lg:gap-y-8 md:grid-cols-2 lg:grid-cols-3">
          {serviceCards.map((service, index) => (
            <motion.div
              key={index}
              ref={ref}
              className="w-full p-8 mb-5 text-center transition-all bg-white rounded shadow hover:shadow-lg"
              initial={isMobile ? 'visible' : 'hidden'}
              animate={isMobile ? 'visible' : (inView ? 'visible' : 'hidden')}
              variants={serviceVariants}
              transition={{ duration: 0.3 }}
            >
              <div className="inline-block p-4 mb-6 -mt-16 bg-red-400 rounded-full">
                <FontAwesomeIcon
                  icon={service.icon}
                  className="text-white"
                  width="40"
                  height="40"
                  fill="currentColor"
                />
              </div>
              <h3 className="mb-4 text-2xl font-semibold">{service.title}</h3>
              <p className="text-base font-regular text-gray-500">{service.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
