import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

const CookieConsent = () => {
    const [cookies, setCookie] = useCookies(['userAcceptedCookies']);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!cookies.userAcceptedCookies) {
            setOpen(true);
        }
    }, [cookies]);

    const handleAccept = () => {
        setCookie('userAcceptedCookies', 'true', { path: '/' });
        setOpen(false);
    };

    const handleDecline = () => {
        setOpen(false);
    };

    if (!open) return null;

    return (

        <div className="max-w-screen-lg mx-auto fixed bg-white inset-x-5 p-5 bottom- md:bottom-1 rounded-lg drop-shadow-2xl flex gap-4 flex-wrap md:flex-nowrap text-center md:text-left items-center justify-center md:justify-between z-50">
            <div className="w-full">This website uses cookies to ensure you get the best experience on our website. </div>
            <div className="flex gap-4 items-center flex-shrink-0">
                <button onClick={handleDecline} className="text-red-600 focus:outline-none hover:underline">Decline</button>
                <button onClick={handleAccept} className="bg-red-500 px-5 py-2 text-white rounded-md hover:bg-red-700 focus:outline-none">Allow Cookies</button>
            </div>
        </div>
    );
};

export default CookieConsent;
