import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ContactFormSection = () => {
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    subject: '',
    email: '',
    message: '',
  });

  const [submitSuccess, setSubmitSuccess] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "958dc9f1-8f02-46ae-8cc6-9b19d233fa7b");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      setSubmitSuccess(true);
      setFormValues({
        firstName: '',
        lastName: '',
        subject: '',
        email: '',
        message: '',
      });
      console.log("Success", res);
    }
  };

  const formVariants = {
    hidden: { scale: 0 },
    visible: { scale: 1 },
  };

  return (
    <div id="contact" className="relative flex items-center justify-center w-full h-screen bg-center bg-cover" style={{backgroundImage: 'url(./assets/contact.jpg)'}}>
      <div className="absolute top-0 bottom-0 left-0 right-0 bg-gray-900 opacity-75"></div>
      <motion.form
        ref={ref}
        onSubmit={onSubmit}
        className="z-10 p-6 mx-2 rounded-md lg:mx-0 bg-stone-100"
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        variants={formVariants}
        transition={{ duration: 0.5 }}
      >
        {submitSuccess && (
          <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md mb-8" role="alert">
            <div className="flex">
              <div className="py-1">
                <svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </div>
              <div>
                <p className="font-bold">Message Sent</p>
                <p className="text-sm">Someone from our team will contact you shortly.</p>
              </div>
            </div>
          </div>
        )}
        <div className="mb-6">
          <h2 className="text-4xl font-bold text-gray-700">Contact Us</h2>
        </div>
        <div className="flex flex-wrap mb-4 -mx-2">
          <div className="w-full px-2 mb-4 lg:mb-0 lg:w-1/2">
            <input
              className="w-full px-3 py-2 font-regular leading-loose border rounded-md"
              type="text"
              name="firstName"
              placeholder="First Name.."
              required
              value={formValues.firstName}
              onChange={(event) => setFormValues({ ...formValues, firstName: event.target.value })}
            />
          </div>
          <div className="w-full px-2 lg:w-1/2">
            <input
              className="w-full px-3 py-2 font-regular leading-loose border rounded-md"
              type="text"
              placeholder="Last Name.."
              name="lastName"
              required
              value={formValues.lastName}
              onChange={(event) => setFormValues({ ...formValues, lastName: event.target.value })}
            />
          </div>
        </div>
        <input
          type="text"
          placeholder="I'm asking information for..."
          required
          className="block w-full px-4 font-regular py-3 mb-3 leading-tight text-gray-700 border rounded bg-gray-50"
          name="subject"
          value={formValues.subject}
          onChange={(event) => setFormValues({ ...formValues, subject: event.target.value })}
        />
        <input
          className="w-full px-3 py-2 mb-4 font-regular leading-loose border rounded-md"
          type="email"
          placeholder="abc@gmail.com"
          name="email"
          required
          value={formValues.email}
          onChange={(event) => setFormValues({ ...formValues, email: event.target.value })}
        />
        <textarea
          rows="4"
          type="message"
          name="message"
          placeholder="Write a message..."
          required
          className="block w-full px-4 font-regular py-2 leading-tight text-gray-700 border rounded bg-gray-50"
          value={formValues.message}
          onChange={(event) => setFormValues({ ...formValues, message: event.target.value })}
        ></textarea>
        <button
          type="submit"
          className="w-full py-4 text-sm font-bold leading-normal text-white transition-all duration-300 bg-red-600 rounded-md hover:bg-red-700"
        >
          Send Message
        </button>
      </motion.form>
    </div>
  );
};

export default ContactFormSection;
