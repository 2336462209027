import React, { useState, useEffect } from 'react'; // <-- Import useEffect
import TOSContent from './TOSContent';
import PrivacyPolicyContent from './PrivacyPolicyContent';

const Footer = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');

    const openModal = (title, contentComponent) => {
        setModalTitle(title);
        setModalContent(contentComponent);
        setModalOpen(true);
        document.body.classList.add('no-scroll'); // <-- Block scrolling of background
    };

    const closeModal = () => {
        setModalOpen(false);
        document.body.classList.remove('no-scroll'); // <-- Restore scrolling
    };

    useEffect(() => {
        return () => {
            document.body.classList.remove('no-scroll'); // <-- Ensure class is removed when component unmounts
        };
    }, []);

    return (
        <>
            <footer className="bg-white rounded-lg shadow dark:bg-gray-900 m-4">
                <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                    <div className="sm:flex sm:items-center sm:justify-between">
                        <a href="/" className="text-3xl text-black dark:text-white font-semibold leading-none">
                            FAME.NET
                        </a>
                        <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                            <li>
                                <button onClick={() => openModal('Terms of Service', <TOSContent />)} className="mr-4 hover:underline md:mr-6 bg-transparent border-none cursor-pointer">Terms of Service</button>
                            </li>
                            <li>
                                <button onClick={() => openModal('Privacy Policy', <PrivacyPolicyContent />)} className="mr-4 hover:underline md:mr-6 bg-transparent border-none cursor-pointer">Privacy Policy</button>
                            </li>
                            <li>
                                <a href="#contact" className="hover:underline">Contact</a>
                            </li>
                        </ul>
                    </div>
                    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                    <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 Swaha Capital LLC. All Rights Reserved.</span>
                </div>
            </footer>

            {isModalOpen && (
                <>
                    <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"></div>
                    <div
                        id="staticModal"
                        data-modal-backdrop="static"
                        tabIndex="-1"
                        aria-hidden="true"
                        className={`fixed top-0 left-0 right-0 bottom-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto flex items-center justify-center`}
                    >
                        <div className="relative w-full max-w-2xl max-h-full md:w-[90%] md:h-[70vh]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                        {modalTitle}
                                    </h3>
                                    <button type="button" onClick={closeModal}className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                </div>
                                <div className="p-6 space-y-6 overflow-y-auto h-[calc(70vh-60px)]">
                                    {modalContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Footer;
